<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="b-container">
      <div class="con-1">
		<div class="title"><p>新时代文明实践</p></div>
        <div class="mod1 flex flex-v">
			<div class="item item1">
					
						<span class="txt"><p>（一）明确中国特色社会主义最本质的特征是中国共产党领导，中国特色社会主义制度的最大优势是中国共产党领导，中国共产党是最高政治领导力量，全党必须增强“四个意识”、坚定“四个自信”、做到“两个维护”；</p> 
						<p>（二）明确坚持和发展中国特色社会主义，总任务是实现社会主义现代化和中华民族伟大复兴，在全面建成小康社会的基础上，分两步走在本世纪中叶建成富强民主文明和谐美丽的社会主义现代化强国，以中国式现代化推进中华民族伟大复兴； </p>
							<p>（三）明确新时代我国社会主要矛盾是人民日益增长的美好生活需要和不平衡不充分的发展之间的矛盾，必须坚持以人民为中心的发展思想，发展全过程人民民主，推动人的全面发展、全体人民共同富裕取得更为明显的实质性进展； </p>
								<p>（四）明确中国特色社会主义事业总体布局是经济建设、政治建设、文化建设、社会建设、生态文明建设五位一体，战略布局是全面建设社会主义现代化国家、全面深化改革、全面依法治国、全面从严治党四个全面；</p>
								</span>
					
					
				</div>
				
		</div>
		
				<div class="pic">
						<img src="../assets/images/wt32.png">
					</div>
					<div class="img">
<img src="../assets/images/wt33.png">
					</div>
					<div class="pho">
						<img src="../assets/images/wt34.png">
					</div>
				

            </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head_sm.vue";

export default {
  data() {
    return {
     
  components: {
    Head,
  },
  computed: {
    scrollOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {

  },


	}
  }
}
</script>

<style scoped>
.page {
  background: url(../assets/images/wt31.png) no-repeat center;
  background-size: 100% 100%;
}

.mod1 {
  height: 100%;
  width: 100%;
  
}


.mod1 .item{width: 65%;padding:20px;border-radius:10px;border-style:solid;border-width:length 10px;border-color:#fff;background: linear-gradient(200deg, rgba(255,255,255,0.50) 13%, rgba(121, 123, 126, 0.6) 83%);opacity: 1;box-sizing: border-box;
border: 10px solid ;
border-image: linear-gradient(131deg, #ff0000 11%, rgba(245, 9, 9, 0.5) 88%) 5;

backdrop-filter: blur(50px);
position:absolute;height: 70%;
top: 20%;
left: 15%;
}
.mod1 .item .txt{position: absolute;
left: 70px;
top: 70px;
width: 2397px;
height: 1293px;
opacity: 1;

font-family: Source Han Sans CN;
font-size: 54px;
font-weight: normal;
line-height: 108px;
letter-spacing: 0em;
color: #333333;}
.mod1 .item .txt p{display: flex;justify-content: space-between;margin-top: 5px; text-indent: 50px;
        letter-spacing: 2px;
        line-height: 1.8;}
.mod1 .item .tit{color:#fff;}

.pic{
position: absolute;
left: 3253px;
top: 561px;
width: 587.34px;
height: 1116.55px;
opacity: 1;
}
.img{
position: absolute;
left: 58px;
top: 1469px;
width: 3709px;
height: 668.89px;
opacity: 1;
z-index:-1;
}
.pho{
position: absolute;
left: 51px;
top: 44px;
width: 1486px;
height: 811px;
opacity: 1;
z-index:-1;
}
@import url('../assets/fonts/font.css');
.title p{
	position: absolute;
left: 1644px;
top: 204px;
width: 1750px;
height: 122px;
opacity: 1;

font-family: Alimama_DongFangDaKai_Regular;
font-size: 250px;
font-weight: normal;
line-height: 80px;
letter-spacing: 0em;

color: #333333;
}

</style>
